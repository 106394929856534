import { ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import { apiUtil } from '@/api'
import { convertISODateTime } from '@/@core/utils/filter'

import useToast from '@useToast'

export default function useListHandle() {
  const { toastError } = useToast()

  const filterBlank = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  }
  // Loading
  const loading = ref(true)

  const filter = ref(cloneDeep(filterBlank))
  const items = ref([])

  function prepareDataFlight(params) {
    return params.map(data => {
      const currentDate = data.date
      const flights = []
      for (const flight of data.flights) {
        for (const itinerarie of flight.itineraries) {
          for (const sub of itinerarie) {
            if (moment(currentDate).isSame(moment(sub.departure.at), 'day')) {
              const paxName = `${flight.paxLists[0]?.lastName} ${flight.paxLists[0]?.firstName}${flight.paxLists.length > 1 ? ` +${flight.paxLists.length}` : ''}`
              const tem = {
                id: flight.id,
                startTime: convertISODateTime(sub.departure.at, sub.departure.timeZone).time,
                endTime: convertISODateTime(sub.arrival.at, sub.arrival.timeZone).time,
                arrival: sub.arrival.city,
                bookingCode: flight.bookingCode,
                paxName,
                iataCodeTrip: `${sub.departure.iataCode}${sub.arrival.iataCode}`,
                flight: sub.airline + sub.flightNumber,
              }
              flights.push(tem)
            }
          }
        }
      }
      const count = flights.length
      if (count > 0) {
        return ({
          date: currentDate,
          flights: flights.sort((a, b) => {
            const timeA = moment(a.startTime, 'HH:mm')
            const timeB = moment(b.startTime, 'HH:mm')
            return timeA - timeB
          }),
          count,
          borderColor: 'transparent',
          backgroundColor: '#FDE9EA',
          textColor: '#2B95D0',
        })
      }
      return null
    }).filter(e => e !== null)
  }

  async function fetchItem(isClearFilter) {
    if (isClearFilter) {
      filter.value = cloneDeep(filterBlank)
    }
    try {
      loading.value = true
      const { data } = await apiUtil.getFlightCalendar(filter.value)
      items.value = prepareDataFlight(data)
      loading.value = false
    } catch (error) {
      toastError(error)
    }
  }

  watch(
    [filter],
    () => {
      fetchItem()
    },
    { deep: true },
  )

  return {
    // Loading
    loading,
    filter,
    items,
    // Functions
    fetchItem,
  }
}
